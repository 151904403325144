import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LabelledInput from './LabelledInput';

import { editPSCredential, createPSCredential } from '../actions';
import { closePopup } from '../actions/popup';

import { oauth } from '../utils';
import { checkCredential } from '../promostandards';
import { BASE_ZINDEX } from '../popup-factory';

class EditTenantPSCredentialPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            credentials: null,
            changed: null,
            loading: false,
            errors: {},
        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleEditCredential = this.handleEditCredential.bind(this);
    }

    componentDidMount() {
        oauth('INDEX', 'promostandards-credential', { tenant_id: this.props.tenant_id }).then(({ json }) => {
            this.setState({
                credentials: _.keyBy(json.credentials, 'promo_api_name'),
                changed: {},
            });
        }, function () {
            this.setState({ response: 'Cannot get credentials.' });
        });
 
        if (this.props.identity.company_id === '9da87da3-7ce5-490d-9e0d-d59962ead31f') {
            oauth('GET',  'sanmar-requests/get-account-number', {company_id: this.props.tenant_id }).then(({ json }) => {
            this.setState({sanmar_account : json.sanmar_account_number})
            });
        }

    }

    handleFieldChange(value, field, key) {
        const { changed, credentials } = this.state;
        this.setState({
            changed: {
                ...changed,
                [key]: {
                    ...credentials[key],
                    ...changed[key],
                    [field]: value,
                },
            }
        });
    }

    handleEditCredential() {
        const { onEditPSCredential, onCreatePSCredential, onClosePopup, tenant_id, promo_api_name } = this.props;
        const { credentials, changed, sanmar_account } = this.state;
        const errors = {};
        const promises = _.map(changed, (value, key) => {
            if (_.every(['promo_api_username', 'promo_api_password', 'promo_api_account'], (path) => {
                return (_.get(value, path, '') || '') === (_.get(credentials, [key, path], '') || '');
            })) {
                return;
            }
            const { promo_credential_id } = value || {};
            const ps_company_code = value.ps_company_code;
            const promo_api_account = (value.promo_api_account || '').trim();
            const promo_api_username = (value.promo_api_username || '').trim();
            const promo_api_password = (value.promo_api_password || '').trim();
            if (promo_credential_id && !promo_api_username && !promo_api_password) {
                return onEditPSCredential(
                    promo_credential_id, key, promo_api_account, promo_api_username, promo_api_password, tenant_id, promo_api_name
                );
            }
            if (!promo_api_username || !promo_api_password) {
                errors[key] = 'missing username or password';
                return;
            }
	    if (promo_credential_id) {
                return onEditPSCredential(
                  promo_credential_id, key, promo_api_account, promo_api_username, promo_api_password,
                  tenant_id, promo_api_name
                );
            }
            return onCreatePSCredential(
              key, promo_api_account, promo_api_username, promo_api_password, tenant_id, promo_api_name
            );
	    /*
            return checkCredential({
                company: ps_company_code, username: promo_api_username, password: promo_api_password
            })
                .then((response) => {
                    const error = _.get(response, 'ErrorMessage');
                    //if (_.isEmpty(error)) {
                    if (true) {
                        if (promo_credential_id) {
                            return onEditPSCredential(
                                promo_credential_id, key, promo_api_account, promo_api_username, promo_api_password,
                                tenant_id, promo_api_name
                            );
                        }
                        return onCreatePSCredential(
                            key, promo_api_account, promo_api_username, promo_api_password, tenant_id, promo_api_name
                        );
                    } else {
                        errors[key] = `${_.get(error, 'code')} ${_.get(error, 'description')}`;
                    }
                })
            ;
	    */
        });

        this.setState({ loading: true });
        Promise.all(promises).then(() => {
            this.setState({ errors, loading: false });
            if (_.isEmpty(errors)) {
                onClosePopup();
            }
        });
    }

    render() {
        const {
            tenant_name,
            onClosePopup,
            index
        } = this.props;
        const { errors, loading, changed, credentials, sanmar_account } = this.state;

        return (
            <div id='ps-test-modal' className='reveal small' style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <h3 id='modalTitle'>Edit Promostandards Credentials</h3>
                        <a className='alert button' style={{ position: 'fixed', right: '5rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
                        <a className='button' style={{ position: 'fixed', right: '1rem', top: '1rem' }}
                            onClick={(e) => { e.preventDefault(); this.handleEditCredential(); }}
                        >Save</a>
                    </div>
                </div>
                {!loading ? null : <div style={{
                    background: 'url(/images/gears.gif) no-repeat center center', minWidth: 70, minHeight: 58
                }}></div>}
                <div className='row popup-content'>
                    <p>Distributor: {tenant_name}</p>
                    {_.map(credentials, (c, promo_api_name) => {
                        const error = _.get(errors, c.promo_api_name);
                        return <div key={promo_api_name} className='row'>
                            <label style={{ fontSize: '18px', marginTop: '10px' }}>{c.display_name}</label>
                            {_.isEmpty(error) ? null : <div style={{color: 'red'}}>{error}</div>}
                            <LabelledInput label='Username' field='promo_api_username'
                                autoComplete="off"
                                value={_.get(changed, [promo_api_name, 'promo_api_username'], c.promo_api_username)}
                                onChange={e => this.handleFieldChange(e, 'promo_api_username', promo_api_name)} />
                            <LabelledInput label='Password' field='promo_api_password' type='password'
                                autoComplete="off"
                                value={_.get(
                                    changed,
                                    [promo_api_name, 'promo_api_password'],
                                    c.promo_api_username ? '        ' : ''
                                )}
                                onFocus={(e) => e.target.select()}
                                onChange={e => this.handleFieldChange(e, 'promo_api_password', promo_api_name)} />
                            <LabelledInput label='Account' field='promo_api_account'
                                placeholder='Optional'
                                value={_.get(changed, [promo_api_name, 'promo_api_account'], c.promo_api_account || sanmar_account )}
                                onChange={e => this.handleFieldChange(e, 'promo_api_account', promo_api_name)} />
                        </div>;
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenant_name: state.entities.tenants[ownProps.tenant_id].tenant_name,
        identity:state.identity
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup());
        },
        onEditPSCredential: (promo_credential_id, promo_api_name, promo_api_account, promo_api_username, promo_api_password, tenant_id) => {
            return dispatch(editPSCredential(promo_credential_id, promo_api_name, promo_api_account, promo_api_username, promo_api_password, tenant_id));
        },
        onCreatePSCredential: (promo_api_name, promo_api_account, promo_api_username, promo_api_password, tenant_id) => {
            return dispatch(createPSCredential(promo_api_name, promo_api_account, promo_api_username, promo_api_password, tenant_id));
        },
    };
};

const ConnectedEditTenantPSCredentialPopup = connect(mapStateToProps, mapDispatchToProps)(EditTenantPSCredentialPopup);
export default ConnectedEditTenantPSCredentialPopup;
